
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Regions",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "location_type",
        route: "/location_type",
        icon: "bi bi-signpost-2",
        count: "",
        permission: "location: index",
      },
      {
        name: "idistance",
        route: "/distances",
        icon: "las la-truck",
        count: "",
        permission: "location: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return permissions.value.filter((x) => x.name === item.permission).length > 0;
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
          .then(({ data }) => {
            permissions.value = data;
          })
          .catch((error) => {
            console.log(error);
          });
      setCurrentPageBreadcrumbs("regions", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/regions").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
        });
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
