import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "d-sm-flex flex-row justify-content-between mb-6" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "text-darker mb-0" }
const _hoisted_5 = { class: "text-gray-500 fs-5 fw-bold" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "p-1" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "row g-4" }
const _hoisted_10 = { class: "catalog-card" }
const _hoisted_11 = { class: "right-corner" }
const _hoisted_12 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("catalogs")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dataObject.length) + " " + _toDisplayString(_ctx.$t("ielements")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            type: "text",
            class: "form-control form-control-search w-sm-300px",
            placeholder: `${_ctx.$t('isearch')}`,
            onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
          }, null, 40, _hoisted_8), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataObject, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.name,
          class: "col-md-4 col-lg-3 columns"
        }, [
          (_ctx.canViewCatalog(item))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.route
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "/media/new/shape01.svg" }),
                      _createElementVNode("i", {
                        class: _normalizeClass(item.icon)
                      }, null, 2)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`${item.name}`)), 1),
                      _createElementVNode("h2", null, _toDisplayString(item.count), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}